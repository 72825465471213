<template>
    <section class="technopark">
        <Nav
            class="mt-5 mb-4"
            route-name="TechnologyTransfer"
            parent-page-name="Трансфер технологий"
            current-page-name="Акселератор и Технопарк"
        />

        <div class="technopark__main-img d-none d-xl-flex">
            <div class="technopark__blurred-img mt-lg-auto ms-lg-auto d-flex align-items-center ms-lg-4">
                <h2 class="technopark__title mt-auto ms-lg-5">
                    <!-- {{ getTechnoparkInfo('title') }} -->
                    <a href='https://accel.itmo.ru/' class="text-white" target='_blank'>Акселератор</a> и <a class="text-white" href='https://technopark.itmo.ru/' target='_blank'>Технопарк</a>
                </h2>
                <img
                    src="../../assets/page-logo.svg"
                    class="research-center__logo ms-auto mb-5 me-5 mt-auto d-none d-xl-block"
                    alt=""
                    width="259"
                    height="72"
                >
            </div>
        </div>

        <div class="title-wrapper d-xl-none my-4 my-lg-5">
            <h2 class="technopark__title technopark__title--dark">
                {{ getTechnoparkInfo('title') }}
            </h2>
        </div>

        <div class="research-center__task py-5 d-flex flex-column flex-lg-row align-items-center">
            <div class="col-12 col-lg-8 col-xl-6">
                <img
                    class="task__img"
                    src="../../assets/views/technopark/image.png"
                    alt=""
                >
            </div>

            <div class="col-12 col-lg-4 col-xl-6 d-flex flex-column align-items-center align-items-lg-start mt-4 me-lg-5">
                <h3 class="task__title">{{ getTechnoparkInfo('subtitle_1') }}</h3>
                <div class="task__text mt-3">
                    {{ getTechnoparkInfo('subtext_1') }}
                </div>
            </div>
        </div>

        <div class="technopark-numbers my-5">
            <div class="technopark-numbers__title mb-5">{{ getTechnoparkInfo('subtitle_2') }}</div>

            <div class="d-flex flex-column align-items-center flex-xl-row align-items-xl-start">
                <div class="technopark-card d-flex flex-column align-items-center p-5">
                    <div class="technopark-card__number text-center">{{ getTechnoparkStat(1, 'number') }}</div>
                    <div class="technopark-card__text text-center mt-3">
                        {{ getTechnoparkStat(1, 'description') }}
                    </div>
                </div>

                <div class="technopark-card d-flex flex-column align-items-center p-5 ms-xl-4 mt-4 mt-xl-0">
                    <div class="technopark-card__number text-center">{{ getTechnoparkStat(2, 'number') }}</div>
                    <div class="technopark-card__text text-center mt-3">
                        {{ getTechnoparkStat(2, 'description') }}
                    </div>
                </div>

                <div class="technopark-card d-flex flex-column align-items-center p-5 ms-xl-4 mt-4 mt-xl-0">
                    <div class="technopark-card__number text-center">{{ getTechnoparkStat(3, 'number') }}</div>
                    <div class="technopark-card__text text-center mt-3">
                        {{ getTechnoparkStat(3, 'description') }}
                    </div>
                </div>
            </div>
        </div>

        <div class="technopark-accelerator">
            <div class="technopark-accelerator__title">{{ getTechnoparkInfo('subtitle_3') }}</div>

            <div class="py-5 d-flex flex-column flex-lg-row align-items-center">
                <div class="col-12 col-lg-8 col-xl-6">
                    <img
                        class="task__img"
                        src="../../assets/views/technopark/accelerator.png"
                        alt=""
                        width="588"
                        height="355"
                    >
                </div>

                <div class="technopark__quote ncci-quote-card d-flex flex-column p-5 mt-4 mt-lg-0 col-12 col-lg-4 col-xl-6">
                    <div class="ncci-quote-card__text">
                        {{ getTechnoparkQuote('text') }}
                    </div>

                    <div class="ms-auto mt-4">
                        <div class="ncci-quote-card__author text-end">{{ getTechnoparkQuote('author_name') }}</div>
                        <div class="ncci-quote-card__position mt-1">{{ getTechnoparkQuote('author_post') }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="accelerator-programs py-5">
            <div class="accelerator-programs__title mb-4">{{ getTechnoparkInfo('subtitle_4') }}</div>

            <div class="d-flex flex-column flex-lg-row">
                <div class="col-12 col-lg-6">
                    <div class="accelerator-programs__text mb-4">
                        {{ getTechnoparkInfo('subtext_4') }}
                    </div>

                    <ul class="accelerator-programs__list list-unstyled">
                        <li
                            v-for="(point, index) in getTechnoparkInfo('points_split')"
                            :key="index"
                            class="d-flex align-items-center mt-3"
                        >
                            <img src="../../assets/views/technopark/check.svg" alt="">
                            <div
                                class="ms-3 accelerator-programs__text"
                                v-html="point"
                            >
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="col-12 col-lg-6 d-flex justify-content-center mt-4 mt-lg-0">
                    <img
                        class="technopark__img"
                        src="../../assets/views/technopark/programs.svg"
                        alt=""
                    >
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Nav from "@/components/Nav/Nav";
import {onMounted} from "vue";
import routerMixin from "@/mixins/routerMixin";
import { useStore } from "vuex";
import {computed} from "vue";

export default {
    name: "Technopark",

    components: {
        Nav
    },

    setup() {
        const store = useStore()
        const { isMenuVisible, toggleMenu } = routerMixin();

        onMounted(async () => {
            if (isMenuVisible.value) {
                await toggleMenu()
            }

            document.body.scrollIntoView({ behavior: "smooth" });
        })

        store.dispatch('pages/getAcceleratorTechnopark')
        const technoparkPage = computed(() => store.getters['pages/acceleratorTechnoparkPage'])

        const getTechnoparkInfo = (key) => {
            if (technoparkPage.value) {
                return technoparkPage.value[key]
            }

            return ''
        }

        const getTechnoparkQuote = (key) => {
            if (technoparkPage.value) {
                return technoparkPage.value.quotes[0][key]
            }

            return ''
        }

        store.dispatch('widgets/getTechnoparkStats')
        const technoparkStats = computed(() => store.getters['widgets/technoparkStats'])

        const getTechnoparkStat = (order, key) => {
            if (technoparkStats.value) {
                return technoparkStats.value.items.find(item => item.id === order)[key];
            }
        }

        return {
            getTechnoparkInfo,
            getTechnoparkQuote,
            getTechnoparkStat,
        }
    }
}
</script>
